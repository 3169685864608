<template>
  <div class="videoMonitor__wrapper">
    <div class="breadcrumb"></div>
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="设备号">
                <el-input
                  v-model="formInline.equipmentCode"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Berth_number')">
                <el-input
                  v-model="formInline.berthCode"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Signal_status')">
                <el-select v-model.trim="formInline.state" size="15">
                  <el-option
                    :label="value.name"
                    :value="value.value"
                    :key="value.value"
                    v-for="value in signalList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Operation_situation')">
                <el-select v-model.trim="formInline.handleState" size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.name"
                    :value="value.value"
                    :key="value.value"
                    v-for="value in operationList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.processing_time')">
                <!-- <el-date-picker
                v-model="reportingTime"
                type="datetimerange"
                :clearable="false"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
            >
            </el-date-picker> -->
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defalutDate="reportingTime"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="$route.meta.authority.button.export"
          ></div>
          <div class="col_box h44" v-if="$route.meta.authority.button.export">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <el-button
                v-if="$route.meta.authority.button.export"
                type="info"
                plain
                icon="el-icon-upload2"
                @click="exportFile"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!-- <div class="total-wrapper">
        <div class="item-wrapper">
          <div class="text">待处理</div>
          <span class="unit"><span class="bold-wrap">100</span>条</span>
        </div>
        <div class="item-wrapper">
          <div class="text">手动忽略</div>
          <span class="unit"><span class="bold-wrap">100</span>条</span>
        </div>
        <div class="item-wrapper">
          <div class="text">系统忽略</div>
          <span class="unit"><span class="bold-wrap">100</span>条</span>
        </div>
        <div class="item-wrapper">
          <div class="text">已使用</div>
          <span class="unit"><span class="bold-wrap">100</span>条</span>
        </div>
      </div> -->
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
          ></el-table-column>
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column prop="operatorDesc" :label="$t('searchModule.Operation_situation')" width="120">
            <template slot="header">
              <span class="vertical-middle">操作情况</span>
              <el-tooltip placement="top-start">
                <div slot="content">
                  <span>待处理：信号上传，PDA未确认</span><br />
                  <span
                    >已使用：正常处理，PDA确认信号，使用地磁上报时间为停车记录（出/入）时间</span
                  ><br />
                  <span>手动忽略：PDA手动忽略信号</span><br />
                  <span>系统忽略：</span><br />
                  <li style="padding-left: 20px">
                    双入忽略前一条；双入是指两个入场信号PDA未处理；
                  </li>
                  <li style="padding-left: 20px">
                    双出忽略后一条；双出是指两个出场信号PDA未处理；
                  </li>
                  <li style="padding-left: 20px">
                    相同类型报警时间间隔默认不超过10s，即10秒内重复上报的信号忽略；
                  </li>
                  <li style="padding-left: 20px">
                    单出忽略，无入场信号的情况下，上报了一个出场信息，则系统忽略出场信号，同时忽略本次单出之前的待处理入场信号；
                  </li>
                  <li style="padding-left: 20px">
                    系统出场（C端自主出场、PDA确认出场，以及高位视频自动匹配出场）未被使用的出场信号系统忽略；
                  </li>
                  <li style="padding-left: 20px">
                    PDA修改泊位，该泊位未被使用的地磁信号系统忽略
                  </li>
                  <span
                    >举个例子：地磁上报入场报警，收费员没有及时操作，PDA入场报警记录显示“待处理”状态；紧接着，地磁又上报出场信号系统自动判断为“系统忽略”，同时第一个入场信号“系统忽略”。</span
                  >
                </div>
                <i
                  class="el-icon-question"
                  style="cursor: pointer; padding-left: 5px"
                ></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="handleTime"
            :label="$t('searchModule.Operation_time')"
            width="180"
          ></el-table-column>
          <el-table-column prop="operatorName" :label="$t('searchModule.Operator')"></el-table-column>
          <el-table-column label="操作" align="center" width="90">
            <template slot-scope="scope">
              <el-button type="text" @click="handleGoParkingRecord(scope.row)"
                >停车记录</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 弹窗 -->
        <pic-detail
          :infoSize="4"
          :infoDetail="parkDetail"
          :rowData="rowData"
          :detailFlag="dialogVisible"
          :tableOrder="tableOrder"
          :parkRecordIdTable="parkRecordIdTable"
          @close="dialogVisible = false"
          :total="total1"
          :tableOrderdata="tableOrderdata"
          :parkRecordIdData="parkRecordIdData"
          @getOrder="clildrenHandleCurrentChange"
          :entryPic="entryPic"
          :exitPic="exitPic"
        ></pic-detail>
      </div>
      <!--分页器-->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { getZeroOrLastDateTime } from "@/common/js/utils";
import timeRangePick from "@/components/timePicker";
import { exportExcelNew } from "@/common/js/public.js";
import picDetail from "@/components/picDetail/picDetailRoadside";
import qs from "qs";
export default {
  components: {
    timeRangePick,
    picDetail,
  },
  data() {
    let start = getZeroOrLastDateTime("yyyy-MM-dd HH:mm:ss", -1, true);
    let end = getZeroOrLastDateTime("yyyy-MM-dd HH:mm:ss", 0, true);
    return {
      loading: "",
      signalList: [
        {
          name: "全部",
          value: "",
        },
        {
          name: "驶入",
          value: "1",
        },
        {
          name: "驶出",
          value: "0",
        },
      ],
      operationList: [
        {
          name: "待处理",
          value: "0",
        },
        {
          name: "手动忽略",
          value: "2",
        },
        {
          name: "系统忽略",
          value: "3",
        },
        {
          name: "已使用",
          value: "1",
        },
      ],
      total: 0,
      pageSize: 15,
      page: 1,
      formInline: {
        equipmentCode: "", // 设备号
        berthCode: "", // 泊位号
        state: "", // 信号状态
        handleState: "", // 操作情况
      },
      reportingTime: [start, end], // 上报时间
      tableData: [],
      tableCols: [
        // {
        //   prop: "cityName",
        //   label: this.$t("list.region"),
        //   width: "",
        // },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
          width: "",
        },
        {
          prop: "equitmentName",
          label: this.$t("list.Equipment_name"),
          width: "",
        },
        {
          prop: "equipmentCode",
          label: this.$t("list.Equipment_No"),
          width: "",
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_No"),
          width: "",
        },
        {
          prop: "brandName",
          label: this.$t("list.Brand_Name"),
          width: "",
        },
        {
          prop: "stateDesc",
          label: this.$t("list.Signal_status"),
          width: "",
        },
        {
          prop: "reportTimeStr",
          label: this.$t("list.signal_time"),
          width: "180",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Report_time"),
          width: "180",
        },
        // {
        //   prop: "operatorDesc",
        //   label: "操作情况",
        //   width: "",
        // },
        // {
        //   prop: "handleTime",
        //   label: this.$t("list.Operation_time"),
        //   width: "180",
        // },
        // {
        //   prop: "operatorName",
        //   label: this.$t("list.Operator"),
        //   width: "",
        // },
      ],
      parkDetail: [],
      rowData: {},
      dialogVisible: false,
      total1: 0,
      tableOrder: [
        { label: this.$t("list.order_ID"), value: "payOrderId", width: "" },
        { label: this.$t("list.entry_time"), value: "entryTime", width: "" },
        {
          label: this.$t("list.Appearance_time"),
          value: "exitTime",
          width: "",
        },
        {
          label: this.$t("list.amount_receivable"),
          value: "shouldPay",
          width: "",
          formatter: (row, column) => {
            return row.shouldPay == 0 ? "0元" : row.shouldPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.preferential_amount"),
          value: "agioPay",
          width: "",
          formatter: (row, column) => {
            return row.agioPay == 0 ? "0元" : row.agioPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.Actual_received_amount"),
          value: "money",
          width: "",
          formatter: (row, column) => {
            return row.money == 0 ? "0元" : row.money / 100 + "元";
          },
        },
        {
          label: this.$t("list.Payment_Type"),
          value: "paymentTypeDesc",
          width: "",
        },
        // { label: "支付方式", value: "payTypeDesc", width: "" },
        // { label: "支付终端", value: "devTypeDesc", width: "" },
        {
          label: this.$t("list.Payment_channels"),
          value: "channelTypeDesc",
          width: "",
        },
        {
          label: this.$t("list.Payment_devices"),
          value: "devTypeDesc",
          width: "",
        },
        { label: this.$t("list.Payer"), value: "operatorName", width: "" },
        {
          label: this.$t("list.Payment_yard"),
          value: "payParkName",
          width: "",
        },
        {
          label: this.$t("list.Third_party_transaction_ID"),
          value: "tradeNo",
          width: "",
        },
      ],
      parkRecordIdTable: [
        {
          label: this.$t("list.Refund_method"),
          value: "refundModeName",
        },
        // {
        //   label: "支付方式",
        //   value: "payTypeName"
        // },
        {
          label: this.$t("list.Payment_channels"),
          value: "channelTypeDesc",
        },
        {
          label: this.$t("list.Payment_devices"),
          value: "devTypeDesc",
        },
        {
          label: this.$t("list.refund_reason"),
          value: "refundReasonName",
        },
        {
          label: this.$t("list.Actual_appearance_time"),
          value: "actualExitTime",
          formatter: (row) => {
            if (row.actualExitTime) {
              return this.timestampToTime(row.actualExitTime);
            } else {
              return "";
            }
          },
        },
        {
          label: this.$t("list.refund_amount"),
          value: "actualRefundPay",
          formatter: (row, column) => {
            if (row.actualRefundPay) {
              return Number(row.actualRefundPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: this.$t("list.applicant"),
          value: "operatorName",
        },
        {
          label: this.$t("list.Application_Time"),
          value: "createdTime",
        },
        {
          label: this.$t("list.Refund_time"),
          value: "refundTime",
          formatter: (row) => {
            if (row.refundTime) {
              return this.timestampToTime(row.refundTime);
            } else {
              return "";
            }
          },
        },
      ],
      tableOrderdata: [],
      parkRecordIdData: [],
      entryPic: [],
      exitPic: [],
    };
  },
  created() {
    // console.log(this.reportingTime)
  },
  mounted() {
    this.searchData();
  },
  methods: {
    clildrenHandleCurrentChange(val) {
      this.getOrder(val);
    },
    // 订单记录
    getOrder(pageNum) {
      // GET /payOrder/queryPayRecord    /acb/2.0/payOrder/payOrderByParkRecordId/
      let page = pageNum || 1;
      let opt = {
        url:
          "/acb/2.0/payOrder/payOrderByParkRecordId/" +
          this.rowData.parkRecordId,
        method: "get",
        data: {
          page: page,
          pageSize: this.pageSize,
          parkRecordId: this.rowData.parkRecordId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableOrderdata = res.value;
            this.total1 = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    timeChange(timeArr) {
      console.log("-=-=-=-=-=");
      console.log(timeArr);
      this.formInline.startReportTime = timeArr[0];
      this.formInline.endReportTime = timeArr[1];
    },
    exportFile() {
      // let opt = {};
      // opt = {
      //   pageNum: this.page,
      //     pageSize: this.pageSize,
      //   ...this.formInline,
      // };
      // exportExcelNew("/acb/2.0/geo/query/export", opt);
      const params = {
        pageNum: this.page,
        pageSize: this.pageSize,
        ...this.formInline,
      };
      const qsParams = qs.stringify(params, {
        arrayFormat: "repeat",
      });
      this.$axios
        .post(
          `/acb/2.0/geo/query/export?${qsParams}`,
          {},
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        )
        .then((res) => {
          // if (hint.length > 0) {
          //   MessageBox.alert(hint, this.$t('pop_up.Tips'), {
          //     confirmButtonText: this.$t('pop_up.Determine')
          //   });
          // }
        });
    },
    handleGoParkingRecord(data) {
      if (!data.parkRecordId) {
        return this.$alert("暂无停车记录信息", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
      this.parkRecordId = data.parkRecordId;
      this.rowData = Object.assign({}, data);
      this.dialogVisible = true;
      this.getRsType(data);
      this.getOrder();
      this.refundOrder();
    },
    // 停车记录详情
    getRsType({ parkRecordId }) {
      let opt = {
        url: "/acb/2.0/parkRecord/" + parkRecordId,
        // url: "/acb/2.0/parkRecord/getById/" + parkRecordId,
        method: "get",
        // data: this.formInline,
        success: (res) => {
          if (res.state == 0) {
            this.parkDetail = res.value;
            this.getPic(res.value.entryOprNum, res.value.exitOprNum);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 订单记录
    getOrder(pageNum) {
      // GET /payOrder/queryPayRecord    /acb/2.0/payOrder/payOrderByParkRecordId/
      let page = pageNum || 1;
      let opt = {
        url:
          "/acb/2.0/payOrder/payOrderByParkRecordId/" +
          this.rowData.parkRecordId,
        method: "get",
        data: {
          page: page,
          pageSize: this.pageSize,
          parkRecordId: this.rowData.parkRecordId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableOrderdata = res.value;
            this.total1 = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    refundOrder() {
      // GET    "/acb/2.0/refundRecord/list"
      this.$axios
        .get("/acb/2.0/refundRecord/list", {
          data: {
            page: this.page,
            size: this.pageSize,
            parkRecordId: this.parkRecordId,
            refundState: 5,
          },
        })
        .then((res) => {
          this.parkRecordIdData = res.value.list;
        });
    },
    // 获取停车场详情图片
    getPic(entryOprNum, exitOprNum) {
      this.entryPic = [];
      this.exitPic = [];
      if (entryOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + entryOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.entryPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
      if (exitOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + exitOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.exitPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    searchData() {
      this.tableData = [];
      this.loading = true;
      // this.formInline.startReportTime = this.reportingTime[0];
      // this.formInline.endReportTime = this.reportingTime[1];
      this.$axios
        .get("/acb/2.0/geo/query", {
          data: {
            pageNum: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.value) {
              this.tableData = res.value.list;
              this.total = res.value.total ? res.value.total * 1 : 0;
            } else {
              this.tableData = [];
              this.total = 0;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
.videoMonitor__wrapper {
  .breadcrumb {
    // height: 35px;
  }

  .content {
    // background: #FFFFFF;
    overflow: hidden;

    .pagerWrapper {
      text-align: right;
      margin-top: 18px;
      font-size: 12px;
    }
  }

  // .tableWrapper {
  // padding: 0 20px 10px;
  // }
  .total-wrapper {
    padding: 0 20px;
    margin-bottom: 22px;

    .item-wrapper {
      min-width: 88px;
      margin-right: 16px;
      padding: 6px 16px;
      display: inline-block;
      background: #F4F8FF;
      border-radius: 4px;
    }

    .text {
      line-height: 17px;
      color: #425466;
      font-size: 12px;
    }

    .bold-wrap {
      padding-right: 2px;
      position: relative;
      top: 1px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #0f6eff;
      line-height: 25px;
    }

    .unit {
      color: #6C7293;
      font-size: 12px;
    }
  }
}
</style>
